.page-item {
    &.active{
        .page-link{
            color:var(--#{$variable-prefix}white);
            background-color: var(--#{$variable-prefix}primary);
            border-color: var(--#{$variable-prefix}primary);
        }
    }
    .page-link{
        border-color: var(--#{$variable-prefix}primary-tint-80);
        color: var(--#{$variable-prefix}primary);
    }
}
.page-link {
    &.active,
  .active > & {
    z-index: unset !important;
}
}